import React from "react"
import { Text } from "theme-ui"

import Part from "../../../components/adventures/short-stories/Part"
import Section from "../../../components/adventures/Section"
import Block from "../../../components/adventures/Block"

export default function Part05() {
  return (
    <Part number="5" title="The Golden Guardian" time="10–20 minutes">
      <Section type="Setup">
        <Block type="Secret Context" title="Setup">
          <Text>In this scene, The Party will be challenged by a Giant Eagle who guards the entrance to the Trickster’s Tower.</Text>
        </Block>
      </Section>
      
      
      <Section type="Encounter">
        <Block
          type="Character"
          name="Golden Guardian"
          pronouns="He/His"
          hp="30"
          atk="3"
          specialAbilities={["Extra Attack", "Evasive"]}
        />
        
        <Block type="Secret Context" title="Encounter: The Golden Guardian">
          <Text>The Trickster's Tower is patrolled by a mighty eagle known as The Golden Guardian. The Guardian has a nest near the entrance to the tower that she protects fiercely. She is automatically hostile toward any creatures that approach the tower.</Text>
          <Text>The Trickster has trapped the eagle atop the mountain with a cruel lie, causing her to guard illusory eggs that will never hatch. The illusion is maintained by a golden amulet that is tied around the eagle's neck. If the amulet is broken, it will release her from the spell, and she will become friendly to The Party.</Text>
          <Text>If Lia is accompanying The Party, they will sense the curse placed upon the creature. Lia will tell the adventurers that the amulet around her neck contains foul magic.</Text>
          <Text>If the eagle would be killed or is close to defeat, she will surrender to The Party. In this case, have the eagle retreat to her nest, where she will wrap her wings around her eggs and begin to weep. She will allow the players to touch her and remove the amulet from her neck.</Text>
        </Block>

        <Block type="Exposition" title="The Summit">
          <Text>You breathe a sigh of relief as you clear the piney canopy and find warm light once again. The Trickster's Tower rises in the distance into the sky, concealed partially by fluffy clouds. You feel close to the end.</Text>
          <Text>As you step onto the mountaintop plateau, a large shadow passes overhead. You look up and hear a terrifying screech as a huge creature's wings briefly eclipse the sun. A mighty eagle lands in front of you and glares at you threateningly. A golden amulet appears tied around the creature's neck, drawing attention away from its worn scars.</Text>
          <Text>The eagle is frightening up close, but you can hear a sadness and exhaustion in its menacing cry.</Text>
        </Block>
      </Section>
    </Part>
  )
}
